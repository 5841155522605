import React, { Suspense, useState, useEffect} from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";
import "./assets/css/colors/default.css";
// Include Routes
import routes from "./routes";
import PrivateRoute from './auth/PrivateRoute';
import { UserContext } from './context/userContext';
 import { checkUser } from './auth/magic';

function withLayout(WrappedComponent, hasDarkTopBar, isApp) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar} isApp={isApp}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

function Loader() {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};


export default function App() {

  const [user, setUser] = useState({ isLoggedIn: null, email: '', user:{} });
  const [loading, setLoading] = useState();

  useEffect(() => { 
    const validateUser = async () => {
       setLoading(true); 
      try { 
        await checkUser(setUser);
         setLoading(false);
       } catch (error) {
         console.error(error);
       }
     };
     validateUser();
   }, [user.isLoggedIn]);

   if (loading) {
    return Loader();
  }

    return (
      <React.Fragment>
        <UserContext.Provider value={user}>
        <Router>
          <Suspense fallback={Loader()}>
          {user.isLoggedIn && <Redirect to={{ pathname: '/digital-assets' }} />}
          <Switch>
            {routes.map((route, idx) =>
              route.isWithoutLayout ? (
                (route.isSecure ? <PrivateRoute path={route.path} exact={route.exact} component={route.component} key={idx} />: <Route
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  key={idx}
                />)
              ) : (
                (route.isSecure ? <PrivateRoute path={route.path} exact component={withLayout(route.component, route.isTopbarDark)} key={idx} />: <Route
                  path={route.path}
                  exact
                  component={withLayout(route.component, route.isTopbarDark)}
                  key={idx}
                />)
              )
            )}
          </Switch>
          </Suspense>
        </Router>
        </UserContext.Provider>
      </React.Fragment>
    );

}